









import Vue from 'vue';
import { ApolloQueryResult } from 'apollo-client';
import { NOTICE } from '@/domain/notice/queries/query';
import { NoticeConnection } from '@/domain/notice/model/Notice';

export default Vue.extend({
  name: 'HostNoticeDetail',
  props: {
    id: String,
    forHostOnly: String,
    title: String,
    content: String,
  },
  data() {
    return {
      noticeId: this.id,
      form: {
        title: this.title,
        content: this.content,
      },
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  apollo: {
    form: {
      query: NOTICE,
      variables() {
        return {
          filter: {
            id: this.$data.noticeId,
            forHostOnly: true,
          },
          page: 1,
          size: 1,
        };
      },
      result(
        result: ApolloQueryResult<{ board: { notices: NoticeConnection } }>
      ) {
        this.$data.form.title = result.data.board.notices.edges[0].node.title;
        this.$data.form.content =
          result.data.board.notices.edges[0].node.content;
      },
      update: data => {
        return data.board.notices.edges[0].node;
      },
      skip(): boolean {
        return this.$data.form.title !== undefined;
      },
    },
  },
});
